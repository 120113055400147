import React, { useCallback } from "react"
import styled from "styled-components"
import VoltorbIconUrl from "../../images/votorb-flip/voltorb.png"
import { Probabilities } from "./types"
import { useState } from "react"
import { allCellValues, CellValue } from "../voltorb-flip/types"

const StyledGameCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.25em solid black;
  position: absolute;
  top: 0;
  left: 0;
  color: black;
`

const CellContainer = styled.div<{ isFlipped: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  transform: ${props =>
    props.isFlipped ? "rotateY(180deg)" : "rotateY(0deg)"};
  transform-style: preserve-3d;
  transition: transform 0.25s ease-in-out;
`

const TiledGameCell = styled(StyledGameCell)`
  background-color: rgb(50, 176, 103);
  background-image: repeating-conic-gradient(
    transparent 0% 25%,
    rgba(0, 0, 0, 0.2) 0% 50%
  );
  background-size: 66.666% 66.666%;
  transform: translateZ(0);
`

const RevealedGameCell = styled(StyledGameCell)`
  background-color: rgb(176, 131, 125);
  transform: rotateY(180deg) translateZ(1px);
`

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 100%;
  height: 100%;
  background: none;
  border: 1px solid transparent;
  margin: 0;
  outline: 0;
  padding: 2px;
  cursor: pointer;
  &:focus-visible {
    outline: 0.25em dotted rgb(176, 131, 125);
    border: 1px solid white;
  }
  &:hover {
    /* border: 0.25em dashed black; */
    background-color: rgba(255, 255, 255, 0.2);
  }
`

const ProbablityRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.5em;
  line-height: 1;
  font-family: sans-serif;

  &:first-child {
    /* flex: 1; */
    align-items: center;
    justify-content: space-around;
    font-weight: 700;
    font-size: 2em;
    line-height: 1;
    margin: 2px 0;
  }
`

const Label = styled.span`
  font-weight: 700;
  min-width: 1em;
  text-align: right;
`

const Input = styled.input`
  width: 100%;
  background: none;
  outline: 0;
  border: 0;
  margin: 0;
  text-align: center;
  font-family: sans-serif;
  font-size: 3.5em;
  font-weight: 700;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }
`

const Value = styled.span`
  font-weight: 700;
  font-family: sans-serif;
  font-size: 3.5em;
`

const Voltorb = styled.img`
  width: 1em;
  height: 1em;
`

const getInputValue = (input: HTMLInputElement) => {
  let int = parseInt(input.value)
  const min = parseInt(input.attributes.getNamedItem("min")?.value ?? "0")
  const max = parseInt(input.attributes.getNamedItem("max")?.value ?? "0")
  if (isNaN(int)) {
    return null
  }
  if (!isNaN(min)) {
    int = Math.max(int, min)
  }
  if (!isNaN(max)) {
    int = Math.min(int, max)
  }
  return int
}

type GameCellProps = {
  probabilities?: Probabilities
  actualValue: CellValue | null
  onActualValueChanged: (value: CellValue | null) => void
}

export const GameCell = ({
  probabilities,
  actualValue,
  onActualValueChanged,
}: GameCellProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState<number | null>(actualValue)
  const possibleValues = Object.keys(probabilities ?? [])
    .filter(key => probabilities?.[key] > 0)
    .sort((a, b) => (probabilities?.[a] > probabilities?.[b] ? -1 : 1))

  const handleChange = useCallback(
    e => {
      setInputValue(getInputValue(e.target))
    },
    [setInputValue]
  )

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      const newActualValue =
        inputValue !== null ? allCellValues[inputValue] : null
      onActualValueChanged(newActualValue)
      setIsEditing(false)
      setInputValue(null)
    },
    [onActualValueChanged, setIsEditing, inputValue]
  )

  const handleBlur = useCallback(() => {
    setInputValue(actualValue)
    setIsEditing(false)
  }, [setIsEditing, setInputValue, actualValue])

  return (
    <CellContainer isFlipped={!!probabilities}>
      <RevealedGameCell>
        {isEditing ? (
          <form onSubmit={handleSubmit}>
            <Input
              type="number"
              min="0"
              max="3"
              ref={ref => ref?.focus()}
              value={inputValue ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </form>
        ) : actualValue !== null ? (
          <Button disabled={!probabilities} onClick={() => setIsEditing(true)}>
            {actualValue === 0 ? (
              <img alt="Voltorb" src={VoltorbIconUrl} />
            ) : (
              <Value>{actualValue}</Value>
            )}
          </Button>
        ) : possibleValues?.length === 1 ? (
          possibleValues[0] === "0" ? (
            <img alt="Voltorb" src={VoltorbIconUrl} />
          ) : (
            <Value>{possibleValues[0]}</Value>
          )
        ) : (
          <Button disabled={!probabilities} onClick={() => setIsEditing(true)}>
            {possibleValues.map(key => (
              <ProbablityRow key={key}>
                <Label>
                  {key === "0" ? (
                    <Voltorb alt="Voltorb" src={VoltorbIconUrl} />
                  ) : (
                    `${key}:`
                  )}
                </Label>
                <span>
                  {probabilities?.[key].toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                  %
                </span>
              </ProbablityRow>
            ))}
          </Button>
        )}
      </RevealedGameCell>
      <TiledGameCell />
    </CellContainer>
  )
}
