import type { CellValue } from "../voltorb-flip/types"
import type { Constraint } from "./types"

// #region utilities
/**
 * Utility to create 2D arrays with default values filled (without references)
 * @param rows the number of rows
 * @param columns the number of columns
 * @param fill the value to fill with
 * @returns a new 2D array with the values filled
 */
export function create2DArray<T extends object | null | undefined>(
  rows: number,
  columns: number,
  fill: T
) {
  const result: T[][] = []
  for (let row = 0; row < rows; row++) {
    result[row] = []
    for (let col = 0; col < columns; col++) {
      result[row][col] = fill === Object(fill) ? { ...fill } : fill
    }
  }
  return result
}

/**
 * Utility to deeply copy a 2D array
 * @param array the 2D array to deep copy
 * @returns a copy of the same array without references
 */
function deepCopy2DArray(array: any[][]) {
  const copy = [...array]
  for (let i = 0; i < array.length; i++) {
    copy[i] = [...array[i]]
  }
  return copy
}
// #endregion utilities

/**
 * Gets the maximum coin multiplier a tile can be for a row or column, given the
 * constraints and current solution state.
 * @param cellsRemaining The count of tiles not yet determined by the solver
 *   for the row or column, excluding the tile currently being determined
 * @param runningVoltorbs The running count of Voltorbs determined by the solver
 *  for the row or column
 * @param runningCoinSum The running sum of coin multipliers determined by the
 *  solver for the row or column
 * @param maxVoltorbs The max count of Voltorbs for the row or column,
 *   given by the puzzle
 * @param maxCoinSum The max sum of coin multipliers for the row or column,
 *   given by the puzzle
 * @returns the maximum coin multiplier value that tile can be.
 *   If it must be a Voltorb, returns 0.
 */
function getMaxCoinMultiplier(
  cellsRemaining: number,
  runningVoltorbs: number,
  runningCoinSum: number,
  maxVoltorbs: number,
  maxCoinSum: number
) {
  // Get the count of Voltorbs remaining
  const voltorbsRemaining = maxVoltorbs - runningVoltorbs
  // Get the count of 1x coin multipliers remaining
  const minCoinsRemaining = cellsRemaining - voltorbsRemaining
  // Get the minimum sum of coin multipliers for this row or column
  const minCoinSumRemaining = runningCoinSum + minCoinsRemaining
  // The difference between max and min sums is the max coin multiplier
  return Math.max(Math.min(maxCoinSum - minCoinSumRemaining, 3), 0)
}

/**
 * Gets the minimum and maximum coin multiplier a tile can be for a row or
 * column given the constraints and current solution totals.
 * @param cellsRemaining The count of tiles not yet determined by the solver
 *   for the row or column, excluding the tile currently being determined
 * @param runningTotal The running count of Voltorbs and sum of coin multipliers
 *  determined by the solver for the row or column
 * @param constraint The count of Voltorbs and sum of coin multipliers
 *  defined by the puzzle constraints
 * @returns [min, max] inclusive bounds on the coin value for the tile
 */
function getCoinMultiplierBounds(
  cellsRemaining: number,
  runningTotal: Constraint,
  constraint: Constraint
) {
  // Get the count of Voltorbs remaining
  const voltorbsRemaining = constraint.voltorbCount - runningTotal.voltorbCount
  // Get the count of 3x coin multipliers remaining
  const coinsRemaining = cellsRemaining - voltorbsRemaining
  // Get the minimum sum of coin multipliers for this row or column
  const minCoinSum = runningTotal.coinSum + coinsRemaining
  // Get the maximum sum of coin multipliers for this row or column
  const maxCoinSum = runningTotal.coinSum + coinsRemaining * 3
  // The min is when all the other coin multipliers are maximized
  const minCoin = Math.max(Math.min(constraint.coinSum - maxCoinSum, 3), 1)
  // The max is when all the other coin multipliers are minimized
  // Allow returning zero to show that there's no possible coin multiplier here
  const maxCoin = Math.max(Math.min(constraint.coinSum - minCoinSum, 3), 0)
  return [minCoin, maxCoin]
}

/**
 * Inner recursive call for solving the game in the given state
 * @param curRow the row of the cell being determined
 * @param curCol the column of the cell being determined
 * @param currentSolution the working solution so far
 * @param rowConstraints the Voltorb count and coin sum for each row
 * @param colConstraints the Voltorb count and coin sum for each column
 * @returns an array of solutions for the given state
 */
function _solve(
  curRow: number,
  curCol: number,
  currentSolution: (CellValue | null)[][],
  rowConstraints: Constraint[],
  colConstraints: Constraint[]
) {
  // Check base case: we found a solution!
  if (curRow === currentSolution.length) {
    return [currentSolution as CellValue[][]]
  }

  // Get running totals
  let rowRunningTotals: Constraint = { voltorbCount: 0, coinSum: 0 }
  let colRunningTotals: Constraint = { voltorbCount: 0, coinSum: 0 }
  for (let row = 0; row < rowConstraints.length; row++) {
    colRunningTotals.coinSum += currentSolution[row][curCol] ?? 0
    colRunningTotals.voltorbCount += currentSolution[row][curCol] === 0 ? 1 : 0
  }
  for (let col = 0; col < colConstraints.length; col++) {
    rowRunningTotals.coinSum += currentSolution[curRow][col] ?? 0
    rowRunningTotals.voltorbCount += currentSolution[curRow][col] === 0 ? 1 : 0
  }

  // Get the min and max coin multiplier for the current cell
  const [minRowCoin, maxRowCoin] = getCoinMultiplierBounds(
    rowConstraints.length - curCol - 1,
    rowRunningTotals,
    rowConstraints[curRow]
  )
  const [minColCoin, maxColCoin] = getCoinMultiplierBounds(
    colConstraints.length - curRow - 1,
    colRunningTotals,
    colConstraints[curCol]
  )
  const minCoin = Math.max(minRowCoin, minColCoin)
  const maxCoin = Math.min(maxRowCoin, maxColCoin)

  // Check the Voltorb count constraint to see if the tile can be a Voltorb
  const canBeVoltorb =
    colRunningTotals.voltorbCount < colConstraints[curCol].voltorbCount &&
    rowRunningTotals.voltorbCount < rowConstraints[curRow].voltorbCount

  let allSolutions: CellValue[][][] = []
  const nextCol = (curCol + 1) % colConstraints.length
  const nextRow = nextCol === 0 ? curRow + 1 : curRow

  // Find all solutions when trying values from the min to max coin multipliers
  for (let coin = minCoin; coin <= maxCoin; coin++) {
    const nextSolution = deepCopy2DArray(currentSolution)
    nextSolution[curRow][curCol] = coin
    const solutions = _solve(
      nextRow,
      nextCol,
      nextSolution,
      rowConstraints,
      colConstraints
    )
    allSolutions = allSolutions.concat(solutions)
  }

  // Check Voltorbs too, if the current tile can be a Voltorb
  if (canBeVoltorb) {
    const nextSolution = deepCopy2DArray(currentSolution)
    nextSolution[curRow][curCol] = 0
    const solutions = _solve(
      nextRow,
      nextCol,
      nextSolution,
      rowConstraints,
      colConstraints
    )
    allSolutions = allSolutions.concat(solutions)
  }

  return allSolutions
}

/**
 * Gets all possible solutions to a puzzle defined by the constraints
 * @param rowConstraints the Voltorb count and coin sum for each row
 * @param colConstraints the Voltorb count and coin sum for each column
 */
export function solve(
  rowConstraints: Constraint[],
  colConstraints: Constraint[]
) {
  return _solve(
    0,
    0,
    create2DArray(rowConstraints.length, colConstraints.length, null),
    rowConstraints,
    colConstraints
  )
}

/**
 * Filters out solutions that don't match the actual solution
 * @param actualSolution
 * @param potentialSolution
 * @returns true if the values in actualSolution match the potentialSolution
 */
export function filterSolution(
  actualSolution: (CellValue | null)[][],
  potentialSolution: CellValue[][]
) {
  for (let row = 0; row < potentialSolution.length; row++) {
    for (let col = 0; col < potentialSolution[row].length; col++) {
      if (
        actualSolution[row][col] !== null &&
        potentialSolution[row][col] !== actualSolution[row][col]
      ) {
        return false
      }
    }
  }
  return true
}

/**
 * Calculates the probabity for each possible value for every cell
 * @param solutions the list of solutions
 * @returns the percentage probability for each tile of each value
 */
export function calculateProbabilities(solutions?: CellValue[][][]) {
  // All the probabilities are 0 in this case, but we don't know the grid size!
  if (!solutions || solutions.length === 0) {
    return undefined
  }
  // Init our count of each cell value for every tile in the grid to 0
  const probabilities = create2DArray(
    solutions[0].length,
    solutions[0][0].length,
    { 0: 0, 1: 0, 2: 0, 3: 0 }
  )
  for (let row = 0; row < probabilities.length; row++) {
    for (let col = 0; col < probabilities[row].length; col++) {
      // First get the numerator for each cell value probability
      for (let solNum = 0; solNum < solutions.length; solNum++) {
        // Increment the count of solutions with this solution's cell value
        probabilities[row][col][solutions[solNum][row][col]]++
      }
      // Then convert to probabilities by dividing by total count of solutions
      for (const key of Object.keys(probabilities[row][col])) {
        probabilities[row][col][key] =
          (100.0 * probabilities[row][col][key]) / solutions.length
      }
    }
  }
  return probabilities
}
