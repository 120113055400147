import React, { useState, useMemo, useCallback, useEffect } from "react"
import styled from "styled-components"
import { getGameState, convertToGameGrid, generateGameGrid } from "./functions"
import { GameCell } from "./GameCell"
import { GameGrid } from "./GameGrid"
import { levelMap } from "./level-map"
import { Cell, CellValue } from "./types"

const GameRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  position: relative;
  overflow: hidden;
  font-family: "Pokemon Pixel Font Regular";
  word-spacing: 4px;
  font-size: 8px;
  color: white;
  background: rgb(24, 128, 96);
  border-radius: 0.5em;
`

const Header = styled.div`
  width: 100%;
  border-top: 0.5em solid white;
  border-bottom: 0.5em solid white;
  text-align: center;
`

const Text = styled.div`
  flex: 1;
  font-size: 3.5em;
  text-shadow: 1px 1px 0 black;
`

const Scores = styled.div`
  text-align: center;
  width: 100%;
  background: rgb(50, 176, 103);
`

const TextBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1em;
  margin: 1em;
  background-color: #eee;
  border-radius: 0.5em;
  border: 0.5em solid rgb(162, 191, 178);
  outline: 0.25em solid black;
  color: black;
`

const TextBoxText = styled(Text)`
  text-shadow: 1px 1px rgb(162, 191, 178);
`

const ScoreValue = styled.div`
  font-family: sans-serif;
  font-weight: 700;
  font-size: 4em;
  text-shadow: 1px 1px rgb(162, 191, 178);
`

const EndScreen = styled.div<{ isOver }>`
  position: absolute;
  bottom: ${props => (props.isOver ? "0" : "-32em")};
  left: 0;
  right: 0;
  z-index: 100;

  transition: bottom ease-in-out 0.25s;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12em;
    box-shadow: 0 0 8em black;
    text-align: center;
  }
`

const StyledCell = styled.div`
  width: 10em;
  height: 10em;
  margin: 0;
  padding: 0.25em;
  box-sizing: border-box;
  border: 0.25em solid white;
  border-radius: 0.25rem;
  background-color: white;
  background-origin: border-box;
  position: relative;
`

const ExplainerRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  margin: 1em;

  @media (max-width: 632px) {
    flex-direction: column;
  }
`

const Explainer = styled.div`
  width: 100%;
  border-top: 0.25em solid black;
  background: rgb(50, 176, 103);
`

const ExplainerTiles = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  min-width: 32em;

  @media (max-width: 632px) {
    min-width: unset;
  }
`

export const VoltorbFlip = () => {
  const [grid, setGrid] = useState<Cell[][]>()
  const [isOver, setIsOver] = useState(false)
  const [isWin, setIsWin] = useState(false)
  const [level, setLevel] = useState(1)
  const [totalScore, setTotalScore] = useState(0)

  // Gets stats about the current game
  // Eg. current score, whether it's a victory, etc
  const gameSummary = useMemo(() => getGameState(grid), [grid])

  // Winning/Losing
  const checkIfGameEnded = useCallback(
    newGrid => {
      if (!newGrid) {
        setIsOver(false)
        return
      }
      const gameState = getGameState(newGrid)
      if (gameState.status === "WON" || gameState.status === "LOST") {
        setIsOver(true)
        setIsWin(gameState.status === "WON")
      } else {
        setIsOver(false)
      }
    },
    [setIsOver]
  )

  // Flip cell tile
  const handleCellClicked = useCallback(
    (row, col) => {
      if (!grid || isOver) {
        return
      }
      const newGrid = grid.map((cells, rowIndex) =>
        cells.map((cell, colIndex) => {
          if (row === rowIndex && col === colIndex) {
            return { ...cell, isRevealed: true }
          }
          return cell
        })
      )
      setGrid(newGrid)
      checkIfGameEnded(newGrid)
    },
    [grid, isOver, checkIfGameEnded]
  )

  // Set user memos
  const handleMemoClicked = useCallback(
    (row, col, cellValue: CellValue) => {
      const newGrid = grid?.map((cells, rowIndex) =>
        cells.map((cell, colIndex) => {
          if (row === rowIndex && col === colIndex) {
            return {
              ...cell,
              userMemo: {
                ...cell.userMemo,
                [cellValue]: !cell.userMemo[cellValue],
              },
            }
          }
          return cell
        })
      )
      setGrid(newGrid)
    },
    [grid, isOver, setGrid]
  )

  // Reset revealed
  const handleResetClicked = useCallback(() => {
    if (!grid) {
      return
    }
    setIsOver(false)

    // Flip all the tiles back over and remove memos
    setGrid(
      grid.map(row =>
        row.map(cell => ({
          ...cell,
          isRevealed: false,
          userMemo: { 0: false, 1: false, 2: false, 3: false },
        }))
      )
    )
    let nextLevel = level
    if (gameSummary.status === "WON") {
      // If they won, on to the next level with their coins
      nextLevel = Math.min(nextLevel + 1, Object.keys(levelMap).length + 1)
      setTotalScore(totalScore => totalScore + gameSummary.score)
    } else if (gameSummary.status === "LOST") {
      // If they lost, send them back to the number of tiles revealed
      nextLevel = Math.max(Math.min(level, gameSummary.revealedCount), 1)
    } else if (gameSummary.status === "PLAYING") {
      // If they lost, send them back to the number of tiles revealed, but keep coins
      nextLevel = Math.max(Math.min(level, gameSummary.revealedCount), 1)
      setTotalScore(totalScore => totalScore + gameSummary.score)
    }

    // Delay regenerating until after the flip animation of the cards (0.25s animation)
    // This prevents the new tiles from flashing in and revealing themselves as the cards flip
    setTimeout(() => {
      const newGrid = convertToGameGrid(generateGameGrid(nextLevel), false)
      setGrid(newGrid)
      setLevel(nextLevel)
    }, 250)
  }, [grid, level, gameSummary, setIsOver, setLevel, setTotalScore])

  // Generate Initial Grid
  useEffect(() => {
    let solutionValues: CellValue[][]
    solutionValues = generateGameGrid(level)
    setGrid(convertToGameGrid(solutionValues))
  }, [])

  // Reset on click anywhere if over
  const handleRootClicked = useCallback(() => {
    if (isOver) {
      handleResetClicked()
    }
  }, [isOver, handleResetClicked])

  return (
    <GameRoot onClick={handleRootClicked}>
      <EndScreen isOver={isOver}>
        <TextBox>
          <TextBoxText>
            {isWin
              ? "Game clear!"
              : "Game over! Click anywhere to try again..."}
          </TextBoxText>
        </TextBox>
      </EndScreen>
      <Header>
        <Text>VOLTORB Flip Level {level}</Text>
        <Text>Flip the Cards and Collect Coins!</Text>
      </Header>
      <Explainer>
        <ExplainerRow>
          <ExplainerTiles>
            <StyledCell>
              <GameCell isRevealed={true} value={1} />
            </StyledCell>
            <StyledCell>
              <GameCell isRevealed={true} value={2} />
            </StyledCell>
            <StyledCell>
              <GameCell isRevealed={true} value={3} />
            </StyledCell>
          </ExplainerTiles>
          <Text>... x1, ... x2, ... x3 coins!</Text>
        </ExplainerRow>
        <ExplainerRow>
          <ExplainerTiles>
            <StyledCell>
              <GameCell isRevealed={true} value={0} />
            </StyledCell>
          </ExplainerTiles>
          <Text>Game Over! 0 coins!</Text>
        </ExplainerRow>
      </Explainer>
      <Scores>
        <TextBox>
          <TextBoxText>Total Coins</TextBoxText>
          <ScoreValue>{`0000${totalScore}`.slice(-5)}</ScoreValue>
        </TextBox>
        <TextBox>
          <TextBoxText>Coins Collected in Current Game</TextBoxText>
          <ScoreValue>{`0000${gameSummary.score}`.slice(-5)}</ScoreValue>
        </TextBox>
      </Scores>
      {grid ? (
        <GameGrid
          grid={grid}
          onCellClicked={handleCellClicked}
          onUserMemoClicked={handleMemoClicked}
          onResetClicked={handleResetClicked}
        />
      ) : null}
    </GameRoot>
  )
}
