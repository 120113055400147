import React from "react"
import styled from "styled-components"
import { convertToGameGrid } from "./functions"
import { GameGrid } from "./GameGrid"
import { CellValue } from "./types"

const Root = styled.div`
  margin: 2rem auto;
`

type VoltorbFlipExampleProps = {
  solution: CellValue[][]
  isRevealed: boolean
  highlightedCells: boolean[][]
  revealedCells: CellValue[][]
  hideColumnConstraints: boolean
}

export const VoltorbFlipExample = ({
  solution,
  highlightedCells,
  revealedCells,
  isRevealed,
  hideColumnConstraints,
}: VoltorbFlipExampleProps) => {
  const grid = convertToGameGrid(
    solution,
    isRevealed,
    revealedCells,
    highlightedCells
  )
  return (
    <Root>
      <GameGrid
        grid={grid}
        hideColumnConstraints={hideColumnConstraints}
      />
    </Root>
  )
}
