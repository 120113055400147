import React from "react"
import styled from "styled-components"
import VoltorbIconUrl from "../../images/votorb-flip/voltorb.png"

const ConstraintCellStyled = styled.div<{color: string}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  place-content: space-between;
  background-color: ${props => props.color ?? 'white'};
`

const Row = styled.div`
  padding: 0 0.25em;
  width: 100%;
`

const VoltorbsContainer = styled(Row)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  border-top: 0.5em solid white;
`
const VoltorbIcon = styled.img`
  flex: 0 1;
  width: 4em;
  margin-right: 0.25em;
`

const Text = styled.div`
  color: black;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 3.5em;
  text-align: right;
  line-height: 100%;
  width: 100%;
`

export const ConstraintCell = ({
  coinSum,
  voltorbCount,
  color
}: {
  coinSum: number
  voltorbCount: number,
  color: string
}) => {
  return (
    <ConstraintCellStyled color={color}>
      <Row>
        <Text>{coinSum}</Text>
      </Row>
      <VoltorbsContainer>
        <VoltorbIcon src={VoltorbIconUrl} alt="Voltorb" />
        <Text>{voltorbCount}</Text>
      </VoltorbsContainer>
    </ConstraintCellStyled>
  )
}
