import { levelMap } from "./level-map"
import { allCellValues, Cell, CellValue } from "./types"

/**
 * Generates a 2D array that represents the solution to a game of Voltorb Flip with the given level
 * @param level The difficulty level to use when generating
 * @returns
 */
export const generateGameGrid = (level: number) => {
  const rowCount = 5
  const colCount = 5
  const totalCellCount = rowCount * colCount
  const configIndex = Math.floor(Math.random() * levelMap[level].length)
  const levelConfig = { ...levelMap[level][configIndex] }
  const grid: CellValue[][] = []
  for (let row = 0; row < rowCount; row++) {
    grid[row] = []
    for (let col = 0; col < colCount; col++) {
      const currentCellNumber = row * colCount + col
      const cellsLeft = totalCellCount - currentCellNumber
      const rng = Math.random() * cellsLeft
      let curSum = 0
      for (const key of allCellValues) {
        curSum += levelConfig[key]
        if (rng < curSum) {
          grid[row][col] = key
          levelConfig[key] = levelConfig[key] - 1
          break
        }
      }
    }
  }
  return grid
}

export const convertToGameGrid = (
  solution: CellValue[][],
  isRevealed: boolean = false,
  revealedValues: CellValue[][] = [],
  highlightedCells: boolean[][] = []
): Cell[][] => {
  return solution.map((row, rowNum) =>
    row.map((value, colNum) => {
      const revealedCell = revealedValues[rowNum]?.[colNum]
      const highlightedCell = highlightedCells[rowNum]?.[colNum]
      return {
        value,
        revealedValue: revealedCell ?? value,
        isRevealed: isRevealed || revealedCell !== undefined,
        isHighlighted: !!highlightedCell,
        userMemo: {
          0: false,
          1: false,
          2: false,
          3: false,
        },
      }
    })
  )
}

export const convertToConstraints = (rowOrColumn: Cell[]) => {
  let coinSum = 0
  let voltorbCount = 0
  for (const cell of rowOrColumn) {
    if (cell.value === 0) {
      voltorbCount++
    } else {
      coinSum += cell.value
    }
  }
  return { coinSum, voltorbCount }
}

export const getGameState = (grid?: Cell[][]) => {
  if (!grid) {
    return {
      status: "PLAYING",
      revealedCount: 0,
      score: 0,
    }
  }
  let didJustWin = true
  let didJustLose = false
  let revealedCount = 0
  let score = 1
  for (let row = 0; row < grid.length; row++) {
    for (let col = 0; col < grid[row].length; col++) {
      if (grid[row][col].isRevealed) {
        if (grid[row][col].value === 0) {
          // Lose
          didJustLose = true
        } else {
          // Multiply score
          score *= grid[row][col].value
          revealedCount++
        }
      } else if (grid[row][col].value > 1) {
        // Still remaining 2x and 3x - no victory
        didJustWin = false
      }
    }
  }
  score = revealedCount > 0 ? score : 0
  if (didJustWin) {
    return { status: "WON", revealedCount, score }
  } else if (didJustLose) {
    return { status: "LOST", revealedCount, score }
  }
  return {
    status: "PLAYING",
    revealedCount,
    score,
  }
}
