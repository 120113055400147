import React, { useEffect } from "react"

export const Tweet = ({ children }: { children: React.ReactNode}) => {

    useEffect(() => {
        const el = document.createElement('script')
        el.src = 'https://platform.twitter.com/widgets.js'
        el.async = true
        el.id = 'twitter-script'
        document.body.appendChild(el)
        return () => {
            document.body.removeChild(el)
        }
    })
    return <>{children}</>
}
