import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { ConstraintCell } from "./ConstraintCell"
import { convertToConstraints } from "./functions"
import { GameCell } from "./GameCell"
import { Cell, CellValue } from "./types"

const StyledCell = styled.td`
  width: 10em;
  height: 10em;
  min-width: 10em;
  min-height: 10em;
  max-width: 10em;
  max-height: 10em;
  margin: 0;
  padding: 0.25em;
  box-sizing: border-box;
  border: 0.25em solid white;
  border-radius: 0.25rem;
  background-color: white;
  background-origin: border-box;
  position: relative;
`

const StyledGameGridCell = styled(StyledCell)<{
  isEditingUserMemo?: boolean
  isFaded?: boolean
  isLinkFaded?: boolean
  rowColor?: string
  columnColor?: string
}>`
  ${props =>
    props.isEditingUserMemo &&
    css`
      /* background-color: red; */
    `}

  ${props =>
    props.isFaded &&
    css`
      filter: brightness(0.5);
    `}

  &::before {
    background: ${props => props.columnColor ?? "white"};
    ${props =>
      props.isLinkFaded &&
      css`
        filter: brightness(0.5);
      `}
  }
  &::after {
    background: ${props => props.rowColor ?? "white"};
    ${props =>
      props.isLinkFaded &&
      css`
        filter: brightness(0.5);
      `}
  }

  tr:not(:last-child) &::before {
    content: " ";
    width: 1em;
    height: 2em;
    position: absolute;
    left: 50%;
    bottom: -2.25em; /* 0 - (height + border size of tile) */
    margin-left: -1em /* 0 - (width / 2 + border size of this) */;
    border-left: 0.5em solid white;
    border-right: 0.5em solid white;
    box-sizing: content-box;
  }
  &:not(:last-child)::after {
    content: " ";
    width: 2em;
    height: 1em;
    position: absolute;
    top: 50%;
    right: -2.25em; /* 0 - (width + border size of tile) */
    margin-top: -1em /* 0 - (height / 2 + border size of this) */;
    border-top: 0.5em solid white;
    border-bottom: 0.5em solid white;
    box-sizing: content-box;
  }
`

const GameGridStyled = styled.table<{ scale: number }>`
  font-size: ${props => props.scale * 8}px;
  border-spacing: 2em;
  border-collapse: separate;
  table-layout: fixed;
  width: unset;
  background: rgb(50, 176, 103);
  border-radius: 0.5em;
  margin: 1em auto;
`

const NiceButton = styled.button`
  background: transparent;
  width: 100%;
  font-size: 3em;
  font-weight: 500;
  color: white;
  padding: 0.125em;
  border-radius: 0.125em;
  text-shadow: 1px 0 0 black, -1px 0 black, 0 1px black, 0 -1px black;
  outline: 1px solid black;
  border: 1px solid black;
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`

const NiceButtonPrimary = styled(NiceButton)`
  background: linear-gradient(
    180deg,
    rgb(108, 167, 243) 0% 40%,
    rgb(79, 134, 235) 40% 50%,
    rgb(52, 101, 219) 50% 100%
  );
  border-color: rgb(52, 101, 219);

  &:active {
    background: linear-gradient(
      0deg,
      rgb(108, 167, 243) 0% 40%,
      rgb(79, 134, 235) 40% 50%,
      rgb(52, 101, 219) 50% 100%
    );
  }
`

const NiceButtonCommon = styled(NiceButton)`
  background: linear-gradient(
    180deg,
    rgb(248, 248, 248) 0% 40%,
    rgb(224, 224, 224) 40% 50%,
    rgb(168, 169, 168) 50% 100%
  );
  border-color: transparent;
  margin-bottom: 4px;

  &:active {
    background: linear-gradient(
      0deg,
      rgb(248, 248, 248) 0% 40%,
      rgb(224, 224, 224) 40% 50%,
      rgb(168, 169, 168) 50% 100%
    );
  }
`

const constraintColors = [
  "rgb(214, 103, 84)",
  "rgb(69, 187, 67)",
  "rgb(228, 156, 56)",
  "rgb(54, 147, 240)",
  "rgb(187, 74, 228)",
]

type GameGridProps = {
  grid: Cell[][]
  onCellClicked?: (row: number, col: number) => void
  onUserMemoClicked?: (row: number, col: number, cellValue: CellValue) => void
  onResetClicked?: () => void
  revealedCells?: CellValue[][]
  highlightedCells?: boolean[][]
  hideColumnConstraints?: boolean
}

export const GameGrid = ({
  grid,
  onCellClicked,
  onUserMemoClicked,
  onResetClicked,
  hideColumnConstraints,
}: GameGridProps) => {
  const [isEditingUserMemo, setIsEditingUserMemo] = useState(false)
  const [scale, setScale] = useState(1)
  // const [originalWidth, setOriginalWidth] = useState<number>()
  const ref = useRef<HTMLTableElement>(null)
  const originalWidth = useRef<number>()

  const hasHighlight = useMemo(() => {
    for (const row of grid) {
      for (const cell of row) {
        if (cell.isHighlighted) {
          return true
        }
      }
    }
    return false
  }, [grid])

  const handleResize = useCallback(() => {
    if (ref.current && ref.current.parentElement && originalWidth.current !== undefined) {
      const newScale =
        ref.current.parentElement.offsetWidth / originalWidth.current
      setScale(Math.max(Math.min(newScale, 1), 0.5))
    }
  }, [ref, originalWidth, setScale])

  useEffect(() => {
    originalWidth.current = ref.current?.offsetWidth
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  

  return grid ? (
    <GameGridStyled
      ref={ref}
      scale={scale}
    >
      <tbody>
        {grid.map((row, rowIndex) => (
          <tr>
            {row.map((cell, colIndex) => (
              <StyledGameGridCell
                isEditingUserMemo={isEditingUserMemo}
                isFaded={hasHighlight && !cell.isHighlighted}
                isLinkFaded={hasHighlight && cell.isHighlighted}
                rowColor={constraintColors[rowIndex]}
                columnColor={constraintColors[colIndex]}
              >
                <GameCell
                  value={cell.revealedValue ?? cell.value}
                  isRevealed={cell.isRevealed}
                  userMemo={cell.userMemo}
                  isEditingUserMemo={isEditingUserMemo}
                  onClick={onCellClicked?.bind(null, rowIndex, colIndex)}
                  onUserMemoClicked={onUserMemoClicked?.bind(
                    null,
                    rowIndex,
                    colIndex
                  )}
                />
              </StyledGameGridCell>
            ))}
            <StyledCell>
              <ConstraintCell
                color={constraintColors[rowIndex]}
                {...convertToConstraints(grid[rowIndex])}
              />
            </StyledCell>
          </tr>
        ))}
        {!hideColumnConstraints ? (
          <tr>
            {grid[0].map((_, colIndex) => (
              <StyledCell>
                <ConstraintCell
                  color={constraintColors[colIndex]}
                  {...convertToConstraints(grid.map(row => row[colIndex]))}
                />
              </StyledCell>
            ))}
            <td>
              {!onResetClicked ? null : (
                <>
                  <NiceButtonCommon
                    onClick={() =>
                      setIsEditingUserMemo(isInMemoMode => !isInMemoMode)
                    }
                  >
                    {isEditingUserMemo ? "Exit" : "Memo"}
                  </NiceButtonCommon>

                  <NiceButtonPrimary onClick={onResetClicked}>
                    Quit
                  </NiceButtonPrimary>
                </>
              )}
            </td>
          </tr>
        ) : null}
      </tbody>
    </GameGridStyled>
  ) : null
}
