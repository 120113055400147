import { CellValue } from "./types"

/** Generated using the below generateLevelMap method */
export const levelMap: Readonly<
  Record<number, Readonly<Record<CellValue, number>[]>>
> = {
  "1": [
    [6, 15, 3, 1],
    [6, 16, 0, 3],
    [6, 14, 5, 0],
    [6, 15, 2, 2],
    [6, 14, 4, 1],
  ],
  "2": [
    [7, 14, 1, 3],
    [7, 12, 6, 0],
    [7, 13, 3, 2],
    [7, 14, 0, 4],
    [7, 12, 5, 1],
  ],
  "3": [
    [8, 12, 2, 3],
    [8, 10, 7, 0],
    [8, 11, 4, 2],
    [8, 12, 1, 4],
    [8, 10, 6, 1],
  ],
  "4": [
    [8, 11, 3, 3],
    [8, 12, 0, 5],
    [10, 7, 8, 0],
    [10, 8, 5, 2],
    [10, 9, 2, 4],
  ],
  "5": [
    [10, 7, 7, 1],
    [10, 8, 4, 3],
    [10, 9, 1, 5],
    [10, 6, 9, 0],
    [10, 7, 6, 2],
  ],
  "6": [
    [10, 8, 3, 4],
    [10, 9, 0, 6],
    [10, 6, 8, 1],
    [10, 7, 5, 3],
    [10, 8, 2, 5],
  ],
  "7": [
    [10, 6, 7, 2],
    [10, 7, 4, 4],
    [13, 5, 1, 6],
    [13, 2, 9, 1],
    [10, 6, 6, 3],
  ],
  "8": [
    [10, 8, 0, 7],
    [10, 5, 8, 2],
    [10, 6, 5, 4],
    [10, 7, 2, 6],
    [10, 5, 7, 3],
  ],
} as const

/**
 * Scrappy generator for the above that uses
 * https://bulbapedia.bulbagarden.net/wiki/Voltorb_Flip
 * @param table The HTML element for the table on bulbapedia
 */
const generateLevelMap = table => {
  const rows = table.querySelectorAll("tr")
  let level = 1
  const res = {}
  for (const rowEl of rows) {
    const row = []
    if (rowEl.children.length === 4) {
      for (const cellEl of rowEl.children) {
        // @ts-ignore this is only used in the console anyway, avoid typing things
        row.push(parseInt(cellEl.textContent))
      }
      res[level - 1].push([
        row[2],
        25 - row[0] - row[1] - row[2],
        row[0],
        row[1],
      ])
    } else if (rowEl.children.length === 1) {
      res[level++] = []
    }
  }
}
