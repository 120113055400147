import React from "react"
import { Link } from "gatsby"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import Toggle from "react-toggle"
import { MDXProvider } from "@mdx-js/react"

import { Spoiler, Callout, Tweet } from "./shortcodes"
import { VoltorbFlip, VoltorbFlipExample } from './voltorb-flip'
import { VoltorbFlipSolver } from "./voltorb-flip-solver"

import IconMoon from '../images/icons/iconMoon.svg'
import IconSun from '../images/icons/iconSun.svg'

const shortcodes = { Tweet, Spoiler, VoltorbFlip, VoltorbFlipExample, Callout, VoltorbFlipSolver }

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header


  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <ThemeToggler>
        {({theme, toggleTheme}) => {
          // Wait until we know what theme to display
          if (theme === null) {
            return null
          }
          return (
            <div className="toggle-dark-mode">
              <Toggle
                checked={theme === 'dark'}
                onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')} 
                icons={{
                  checked: <IconMoon width={20} height={20} fill="yellow" />, 
                  unchecked: <IconSun width={20} height={20} fill="yellow" />
                }} 
              />
            </div>
         )
      }}
      </ThemeToggler>
      <header className="global-header">{header}</header>
      <main><MDXProvider components={shortcodes}>{children}</MDXProvider></main>
      <footer>
        <a title="rickyrombo on Twitter" rel="noopener noreferrer" href="https://twitter.com/rickyrombo" target="_blank">twitter</a>
        {' '}&#8226;{' '}
        <a title="rickyrombo on GitHub" rel="noopener noreferrer" href="https://github.com/rickyrombo" target="_blank">github</a>
        {' '}&#8226;{' '}
        <a title="rickyrombo on SoundCloud" rel="noopener noreferrer" href="https://soundcloud.com/rickyrombo" target="_blank">music</a>
      </footer>
    </div>
  )
}

export default Layout
