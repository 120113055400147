import React, { useCallback, useState } from "react"
import styled from "styled-components"
import VoltorbIconUrl from "../../images/votorb-flip/voltorb.png"
import { allCellValues, Cell, CellValue } from "./types"

const StyledGameCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.25em solid black;
  position: absolute;
  top: 0;
  left: 0;
`

const CellContainer = styled.div<{ isFlipped: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  transform: ${props =>
    props.isFlipped ? "rotateY(180deg)" : "rotateY(0deg)"};
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform 0.25s ease-in-out;
`

const TiledGameCell = styled(StyledGameCell)<{ isInteractive: boolean }>`
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  gap: 33%;
  background-color: rgb(50, 176, 103);
  background-image: repeating-conic-gradient(
    transparent 0% 25%,
    rgba(0, 0, 0, 0.2) 0% 50%
  );
  background-size: 66.666% 66.666%;

  &:hover {
    cursor: ${props => (props.isInteractive ? "pointer" : "auto")};
    filter: ${props => (props.isInteractive ? "brightness(1.1)" : "")};
  }

  & > * {
    width: 33%;
    height: 33%;
  }
`

const RevealedGameCell = styled(StyledGameCell)`
  background-color: rgb(176, 131, 125);
  transform: rotateY(180deg);
  pointer-events: none;
`

const Text = styled.span`
  color: black;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 4em;
`
const VoltorbIcon = styled.img`
  width: 5em;
`

const Memo = styled.div<{ selected: boolean; visible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.25em;
  font-weight: 700;
  color: ${props => (props.selected ? "orange" : "white")};
  opacity: ${props => (props.selected ? 1 : 0.5)};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

type GameCellProps = Omit<Cell, "isHighlighted" | "userMemo"> & {
  onClick?: () => void
  onUserMemoClicked?: (cellValue: CellValue) => void
  isEditingUserMemo?: boolean
  userMemo?: Record<CellValue, boolean>
}

export const GameCell = ({
  value,
  isRevealed,
  onClick,
  userMemo,
  isEditingUserMemo,
  onUserMemoClicked,
}: GameCellProps) => {
  const handleClick = useCallback(() => {
    if (!isEditingUserMemo) {
      onClick?.()
    }
  }, [onClick, isEditingUserMemo])

  return (
    <CellContainer onClick={handleClick} isFlipped={isRevealed}>
      <RevealedGameCell>
        {value !== 0 ? (
          <Text>{value}</Text>
        ) : (
          <VoltorbIcon src={VoltorbIconUrl} alt="Voltorb" />
        )}
      </RevealedGameCell>
      <TiledGameCell isInteractive={!!onClick && !isEditingUserMemo}>
        {!!onClick && userMemo
          ? Object.keys(userMemo).map(cellValue => (
              <Memo
                key={cellValue}
                visible={userMemo[cellValue] || isEditingUserMemo}
                selected={userMemo[cellValue]}
                onClick={e =>
                  onUserMemoClicked?.(parseInt(cellValue) as CellValue)
                }
              >
                {cellValue === "0" ? "\u25cf" : cellValue}
              </Memo>
            ))
          : null}
      </TiledGameCell>
    </CellContainer>
  )
}
