import React, { useCallback } from "react"
import styled from "styled-components"
import VoltorbIconUrl from "../../images/votorb-flip/voltorb.png"
import { Constraint } from "./types"

const ConstraintCellStyled = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  place-content: space-between;
  background-color: ${props => props.color ?? "white"};
`

const Row = styled.div`
  width: 100%;
`

const VoltorbsContainer = styled(Row)`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  border-top: 0.5em solid white;
`
const VoltorbIcon = styled.img`
  flex: 0 1;
  width: 4em;
  margin-inline: 0.25em;
`

const Input = styled.input`
  color: black;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 3.5em;
  text-align: right;
  line-height: 100%;
  width: 100%;
  height: 1em;

  background-color: transparent;
  outline: 0;
  border: 1px solid transparent;
  user-select: all;

  position: relative;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }
  &:focus {
    border-color: #ccc;
    background-color: rgba(255, 255, 255, 1);
  }
`

const Value = styled.div`
  padding: 0 3px;
  color: black;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 3.5em;
  text-align: right;
  line-height: 100%;
  width: 100%;
  height: 1em;
`

type ConstraintCellProps = {
  color: string
  coinSum: number
  voltorbCount: number
  isEditable?: boolean
  onChange: (constraint: Constraint) => void
}

const getInputValue = (input: HTMLInputElement) => {
  let int = parseInt(input.value)
  const min = parseInt(input.attributes.getNamedItem("min")?.value ?? "0")
  const max = parseInt(input.attributes.getNamedItem("max")?.value ?? "0")
  if (isNaN(int)) {
    int = 0
  }
  if (!isNaN(min)) {
    int = Math.max(int, min)
  }
  if (!isNaN(max)) {
    int = Math.min(int, max)
  }
  return int
}

export const ConstraintCell = ({
  color,
  coinSum,
  voltorbCount,
  isEditable = true,
  onChange,
}: ConstraintCellProps) => {
  const handleVoltorbCountChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log(getInputValue(e.target))
      onChange({
        voltorbCount: getInputValue(e.target),
        coinSum,
      })
    },
    [coinSum, onChange]
  )
  const handleCoinSumChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        voltorbCount,
        coinSum: getInputValue(e.target),
      })
    },
    [voltorbCount, onChange]
  )
  return (
    <ConstraintCellStyled color={color}>
      <Row>
        {isEditable ? (
          <Input
            type="number"
            max="15"
            min="0"
            value={coinSum + ''}
            onChange={handleCoinSumChanged}
            onFocus={e => e.target.select()}
            onKeyPress={e => {
              if (e.key === "Enter") {
                e.currentTarget.blur()
              }
            }}
          />
        ) : (
          <Value>{coinSum}</Value>
        )}
      </Row>
      <VoltorbsContainer>
        <VoltorbIcon src={VoltorbIconUrl} alt="Voltorb" />
        {isEditable ? (
          <Input
            type="number"
            max="5"
            min="0"
            value={voltorbCount + ''}
            onChange={handleVoltorbCountChanged}
            onFocus={e => e.target.select()}
            onKeyPress={e => {
              if (e.key === "Enter") {
                e.currentTarget.blur()
              }
            }}
          />
        ) : (
          <Value>{voltorbCount}</Value>
        )}
      </VoltorbsContainer>
    </ConstraintCellStyled>
  )
}
