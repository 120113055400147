import React from "react"
import styled from 'styled-components'
import cn from "classnames"

const StyledCallout = styled.div<{ type: 'danger' | 'warning' | 'info' | ''}>`
  position: relative;
  margin-block-start: 2em;
  margin-block-end: 2em;
  background: rgba(128, 128, 128, 0.1);
  border-radius: 0.5em;
  border: 1px solid rgba(128, 128, 128, 0.1);

  &::before {
    content: '';
    width: 2em;
    height: 2em;
    position: absolute;
    top: -1em;
    left: -1em;
    display: block;
    background-size: cover;
  }


  & .anchor {
    height: calc(100% + 2em);
    display: flex;
    align-items: center;
    margin-top: -1em;
    margin-bottom: -1em;
    
    & svg {
      visibility: hidden;
    }
  }

  &:hover .anchor svg {
    visibility: visible;
  }
`

const StyledCalloutContent = styled.div`
  margin: 2em;
`

const svgIcon = (
  <svg
    aria-hidden="true"
    focusable="false"
    height="16"
    version="1.1"
    viewBox="0 0 16 16"
    width="16"
  >
    <path
      fill-rule="evenodd"
      d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
    ></path>
  </svg>
)

export const Callout = ({
  type = "",
  anchor,
  children,
}: {
  type: "danger" | "warning" | "info" | ""
  anchor?: string
  children: any
}) => {
  return (
    <StyledCallout type={type}>
      {anchor ? (
        <a
          id={anchor}
          // These classnames are used by gatsby-remark-autolink-headers to render the "link" SVG.
          // We're recreating that behavior here for callouts
          className={cn("anchor", "before")} 
          href={`#${anchor}`}
          aria-label={`Link to "${anchor}" callout`}
        >
          {svgIcon}
        </a>
      ) : null}
      <StyledCalloutContent>{children}</StyledCalloutContent>
    </StyledCallout>
  )
}
