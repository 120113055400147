import React, {useMemo, useState} from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'
import styled from 'styled-components'
import uniqueId from 'lodash/uniqueId'

const SpoilerStyled = styled.div`
  margin-bottom: 2.5rem;
  border-width: 1px;
  border-color: rgba(128,128,128,0.1);
  border-top-style: solid;
  border-bottom-style: solid;
  overflow: hidden;
`
const SpoilerHeaderStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75em;
  font-size: 0.8em;
  cursor: pointer;
  padding: 0.5em 1em;
  transition: background-color ease-in-out 0.15s;
  background-color: transparent;
  border: 0;
  width: 100%;
  color: var(--color-text);

  &:hover {
    background-color: rgba(128,128,128,0.05);
  }

  &:focus-visible {
    outline: var(--color-heading-main) auto 1px;
  }
`

const Chevron = styled.svg<{isCollapsed: boolean}>`
  transform: ${props => props.isCollapsed ? 'rotateZ(0deg)' : 'rotateZ(90deg)'};
  transition: transform ease-in-out 0.15s;
`

const SpoilerContentWrapperStyled = styled.div<{isCollapsed: boolean, contentHeight: number}>`
  /* transform: ${props => props.isCollapsed ? 'scaleY(0)' : 'scaleY(1)'}; */
  height: ${props => props.isCollapsed ? '0' : `${props.contentHeight}px`};
  overflow: hidden;
  transition: all ease-in-out 0.15s;
  position: relative;
`

const SpoilerContentStyled = styled.div`
  position: absolute;
  width: 100%;
  padding: 0.5em 0;
  /* border-top: 1px solid rgba(128,128,128,0.1);
  box-sizing: border-box; */

  & > *:not(.gatsby-highlight) {
    margin-left: 1em;
    margin-right: 1em;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

`

export const Spoiler = ({openText = 'Show', closeText = 'Hide', text = '', startOpen = false, children}) => {
  const [isCollapsed, setCollapsed] = useState(!startOpen)
  const [contentHeight, setContentHeight] = useState(0)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const id = useMemo(() => uniqueId('Spoiler-'), [])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const height = entries[0].borderBoxSize[0].blockSize
      setContentHeight(height)
    })
    if (contentRef.current !== null) {
      resizeObserver.observe(contentRef.current)
    }
    return () => resizeObserver.disconnect()
  }, [contentRef, setContentHeight])

  return (
    <SpoilerStyled>
      <SpoilerHeaderStyled aria-expanded={!isCollapsed} aria-controls={id} onClick={() => setCollapsed(!isCollapsed)}>
        <Chevron isCollapsed={isCollapsed} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </Chevron>
        <div>{isCollapsed ? openText : closeText} {text}</div>
      </SpoilerHeaderStyled>
      <SpoilerContentWrapperStyled id={id} isCollapsed={isCollapsed} contentHeight={contentHeight}>
        <SpoilerContentStyled ref={contentRef}>
          {children}
        </SpoilerContentStyled>
      </SpoilerContentWrapperStyled>
    </SpoilerStyled>
  )
}