import React, { useCallback, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import { CellValue } from "../voltorb-flip/types"
import { ConstraintCell } from "./ConstraintCell"
import { GameCell } from "./GameCell"
import {
  calculateProbabilities,
  create2DArray,
  filterSolution,
  solve,
} from "./SolverV2"
import { Constraint, Probabilities } from "./types"

const GameRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  position: relative;
  overflow: hidden;
`
const StyledCell = styled.td`
  width: 10em;
  height: 10em;
  min-width: 10em;
  min-height: 10em;
  max-width: 10em;
  max-height: 10em;
  margin: 0;
  padding: 0.25em;
  box-sizing: border-box;
  border: 0.25em solid white;
  border-radius: 0.25rem;
  background-color: white;
  background-origin: border-box;
  position: relative;
`

const StyledGameGridCell = styled(StyledCell)<{
  isFaded?: boolean
  isLinkFaded?: boolean
  rowColor?: string
  columnColor?: string
}>`
  ${props =>
    props.isFaded &&
    css`
      filter: brightness(0.5);
    `}

  &::before {
    background: ${props => props.columnColor ?? "white"};
    ${props =>
      props.isLinkFaded &&
      css`
        filter: brightness(0.5);
      `}
  }
  &::after {
    background: ${props => props.rowColor ?? "white"};
    ${props =>
      props.isLinkFaded &&
      css`
        filter: brightness(0.5);
      `}
  }

  tr:not(:last-child) &::before {
    content: " ";
    width: 1em;
    height: 2em;
    position: absolute;
    left: 50%;
    bottom: -2.25em; /* 0 - (height + border size of tile) */
    margin-left: -1em /* 0 - (width / 2 + border size of this) */;
    border-left: 0.5em solid white;
    border-right: 0.5em solid white;
    box-sizing: content-box;
  }
  &:not(:last-child)::after {
    content: " ";
    width: 2em;
    height: 1em;
    position: absolute;
    top: 50%;
    right: -2.25em; /* 0 - (width + border size of tile) */
    margin-top: -1em /* 0 - (height / 2 + border size of this) */;
    border-top: 0.5em solid white;
    border-bottom: 0.5em solid white;
    box-sizing: content-box;
  }
`

const GameGridStyled = styled.table`
  font-size: 8px;
  border-spacing: 2em;
  border-collapse: separate;
  table-layout: fixed;
  width: unset;
  background: rgb(50, 176, 103);
  border-radius: 0.5em;
  margin: 0;
`

const NiceButton = styled.button`
  background: linear-gradient(
    180deg,
    rgb(108, 167, 243) 0% 40%,
    rgb(79, 134, 235) 40% 50%,
    rgb(52, 101, 219) 50% 100%
  );
  width: 100%;
  font-size: 2em;
  font-weight: 500;
  color: white;
  font-family: sans-serif;
  padding: 0.5em;
  border-radius: 0.25em;
  text-shadow: 0 0 2px black;
  outline: 1px solid black;
  border: 1px solid rgb(52, 101, 219);
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
    background: linear-gradient(
      0deg,
      rgb(108, 167, 243) 0% 40%,
      rgb(79, 134, 235) 40% 50%,
      rgb(52, 101, 219) 50% 100%
    );
  }
`

const Bubble = styled.div<{ isVisible }>`
  position: absolute;
  top: ${props => (props.isVisible ? "15%" : "0")};
  z-index: 100;

  transition: top ease-in-out 0.5s;

  & > div {
    margin-top: ${props => (props.isVisible ? "0" : "-6em")};
    padding: 0.25em 1em;
    background: var(--color-background);
    border-radius: 1rem;
    box-shadow: 0 0 4em black;

    font-weight: 700;
    font-size: 1.2em;
    font-family: sans-serif;
    text-align: center;

    transition: margin-top ease-in-out 0.5s;
  }
`

const constraintColors = [
  "rgb(214, 103, 84)",
  "rgb(69, 187, 67)",
  "rgb(228, 156, 56)",
  "rgb(54, 147, 240)",
  "rgb(187, 74, 228)",
]

export const GameGrid = () => {
  const [rowConstraints, setRowConstraints] = useState<Constraint[]>(
    new Array(5).fill({ voltorbCount: 0, coinSum: 0 })
  )
  const [colConstraints, setColConstraints] = useState<Constraint[]>(
    new Array(5).fill({ voltorbCount: 0, coinSum: 0 })
  )

  // const [rowConstraints, setRowConstraints] = useState<Constraint[]>([
  //   { coinSum: 3, voltorbCount: 2 },
  //   { coinSum: 4, voltorbCount: 1 },
  //   { coinSum: 3, voltorbCount: 3 },
  //   { coinSum: 2, voltorbCount: 3 },
  //   { coinSum: 8, voltorbCount: 1 },
  // ])
  // const [colConstraints, setColConstraints] = useState<Constraint[]>([
  //   { coinSum: 4, voltorbCount: 2 },
  //   { coinSum: 4, voltorbCount: 3 },
  //   { coinSum: 3, voltorbCount: 2 },
  //   { coinSum: 5, voltorbCount: 1 },
  //   { coinSum: 4, voltorbCount: 2 },
  // ])
  const [actualSolution, setactualSolution] = useState<
    Array<CellValue | null>[]
  >(create2DArray(5, 5, null))
  const [solutions, setSolutions] = useState<Array<CellValue>[][]>()

  const handleRowConstraintChanged = useCallback(
    (index: number, value: Constraint) => {
      setRowConstraints(rowConstraints =>
        rowConstraints.map((row, rowIndex) => {
          if (rowIndex === index) {
            return value
          }
          return row
        })
      )
    },
    [setRowConstraints]
  )

  const handleColConstraintChanged = useCallback(
    (index: number, value: Constraint) => {
      setColConstraints(colConstraints =>
        colConstraints.map((col, colIndex) => {
          if (colIndex === index) {
            return value
          }
          return col
        })
      )
    },
    [setColConstraints]
  )

  const handleSolveClicked = useCallback(
    e => {
      e.stopPropagation()
      if (!solutions) {
        const newSolutions = solve(rowConstraints, colConstraints)
        setSolutions(newSolutions)
      } else {
        setSolutions(undefined)
        setactualSolution(create2DArray(5, 5, null))
      }
    },
    [rowConstraints, colConstraints, solutions, setSolutions]
  )

  const handleSetActualValue = useCallback(
    (actualValue, row, col) => {
      const copy = [...actualSolution]
      if (!copy[row]) {
        copy[row] = []
      }
      copy[row][col] = actualValue
      setactualSolution(copy)
    },
    [setactualSolution, actualSolution]
  )

  const filteredSolutions = useMemo(
    () =>
      solutions?.filter(solution => filterSolution(actualSolution, solution)),
    [solutions, actualSolution]
  )
  const probabilities = useMemo(
    () => calculateProbabilities(filteredSolutions),
    [filteredSolutions]
  )

  const noSolution = filteredSolutions && filteredSolutions.length === 0

  return (
    <GameRoot>
      <Bubble isVisible={noSolution}>
        <div>Couldn't find solution for the given constraints.</div>
      </Bubble>
      <GameGridStyled>
        <tbody>
          {rowConstraints.map((rowConstraint, rowIndex) => (
            <tr>
              {colConstraints.map((_, colIndex) => (
                <StyledGameGridCell
                  key={colIndex}
                  rowColor={constraintColors[rowIndex]}
                  columnColor={constraintColors[colIndex]}
                >
                  <GameCell
                    probabilities={
                      noSolution
                        ? undefined
                        : probabilities?.[rowIndex][colIndex]
                    }
                    actualValue={actualSolution[rowIndex][colIndex]}
                    onActualValueChanged={value =>
                      handleSetActualValue(value, rowIndex, colIndex)
                    }
                  />
                </StyledGameGridCell>
              ))}
              <StyledCell key={rowIndex}>
                <ConstraintCell
                  color={constraintColors[rowIndex]}
                  {...rowConstraint}
                  isEditable={!solutions}
                  onChange={value =>
                    handleRowConstraintChanged(rowIndex, value)
                  }
                />
              </StyledCell>
            </tr>
          ))}
          <tr>
            {colConstraints.map((colConstraint, colIndex) => (
              <StyledCell key={colIndex}>
                <ConstraintCell
                  color={constraintColors[colIndex]}
                  {...colConstraint}
                  isEditable={!solutions}
                  onChange={value =>
                    handleColConstraintChanged(colIndex, value)
                  }
                />
              </StyledCell>
            ))}
            <td>
              <NiceButton onClick={handleSolveClicked}>
                {!solutions ? "Solve" : "Reset"}
              </NiceButton>
            </td>
          </tr>
        </tbody>
      </GameGridStyled>
    </GameRoot>
  )
}
